<template>
  <div
    style="height: 100vh; width: 100vw"
    v-loading="true"
    element-loading-text="登录校验中,请稍后..."
    element-loading-background="rgba(0, 0, 0, 0.8)"
  ></div>
</template>

<script>
import { Base64 } from "@/utils/Tools.js"; //引入
export default {
  data() {
    return {
      base: new Base64(),
    };
  },
  created() {
    let str = this.base.decode(this.$route.query.c);
    if(this.$route.query.d){
      sessionStorage.setItem('Demologin','1')
    }
    this.$http
      .post("/Base_Manage/Home/PhoneLogin", {
        userName: str,
      })
      .then((res) => {
        this.loading = false;
        if (res.Success) {
          sessionStorage.setItem("token", res.Data);
          this.$router.replace("/information");
        } else {
          this.$message.error(res.Msg);
        }
      });
  },
};
</script>

<style>
</style>